<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M25.35 5.00026H6.65C6.22584 4.99271 5.81561 5.15227 5.50719 5.44476C5.19878 5.73726 5.01671 6.13941 5 6.56507V25.4349C5.01671 25.8606 5.19878 26.2627 5.50719 26.5552C5.81561 26.8477 6.22584 27.0073 6.65 26.9997H25.35C25.7742 27.0073 26.1844 26.8477 26.4928 26.5552C26.8012 26.2627 26.9833 25.8606 27 25.4349V6.56507C26.9833 6.13941 26.8012 5.73726 26.4928 5.44476C26.1844 5.15227 25.7742 4.99271 25.35 5.00026ZM11.5083 23.7781H8.3V13.2846H11.5083V23.7781ZM9.95 11.8118C9.4402 11.8094 8.95197 11.605 8.59148 11.243C8.231 10.881 8.02741 10.3907 8.025 9.8788C8.01871 9.62323 8.06421 9.36905 8.15872 9.13167C8.25324 8.89429 8.3948 8.67867 8.57482 8.4979C8.75484 8.31713 8.96957 8.17498 9.20596 8.08007C9.44235 7.98516 9.69549 7.93948 9.95 7.94579C10.4605 7.94579 10.9502 8.14945 11.3112 8.51196C11.6722 8.87447 11.875 9.36614 11.875 9.8788C11.875 10.3915 11.6722 10.8831 11.3112 11.2456C10.9502 11.6082 10.4605 11.8118 9.95 11.8118ZM23.7917 23.686H20.5833V18.5313C20.5833 17.3347 20.5833 15.6778 18.8417 15.6778C17.1 15.6778 16.9167 17.0586 16.9167 18.3472V23.594H13.7083V13.2846H16.7333V14.6653H16.825C17.1463 14.1251 17.6033 13.6791 18.1503 13.3722C18.6974 13.0652 19.3151 12.9081 19.9417 12.9164C23.2417 12.9164 23.8833 15.1255 23.8833 17.979V23.686H23.7917Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconLinkedIn",
});
</script>
